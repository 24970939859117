/**
 * 路由配置信息
 *
 * @author terrfly
 * @date 2021/5/8 07:18
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// hack router push callback
// [解决 vue-router跳转相同路径报错 ]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [
    {path: '/hub/:CloudPayToken', name: 'Hub', component: () => import('../views/Hub.vue')}, //自动分发器
    // {path: '/error', name: 'Error', component: () => import('../views/Error.vue')},
    // eslint-disable-next-line no-undef
    {path: '/error/', name: 'Error', component: () => import('../views/Error.vue')},
    {path: '/ACardPay/:id', name: 'ACardPay', component: () => import('../views/ACardPay.vue')},
    {path: '/abc/', name: 'abc', component: () => import('../views/abc.vue')},
    {path: '/ACardPayComplete/:id', name: 'ACardPayComplete', component: () => import('../views/ACardPayComplete.vue')},
    {path: '/nrlyPay/:id', name: 'NrlyPay', component: () => import('../views/h5pay/nrlyPay/nrlypay.vue')},
    {path: '/H5plugin/:id', name: 'H5plugin', component: () => import('../views/h5pay/H5PlugInPay/plugin.vue')},
    {
        path: '/saveRollInfo/:id',
        name: 'saveRollInfo',
        component: () => import('../views/h5pay/qrCodePay/saveRollInfo.vue')
    },
    {
        path: '/saveOrderNumber/:id',
        name: 'saveOrderNumber',
        component: () => import('../views/h5pay/qrCodePay/saveOrderNumber.vue')
    },
    {path: '/qrCodeInfo/:id', name: 'qrCodeInfo', component: () => import('../views/h5pay/qrCodePay/qrCodeInfo.vue')},
    {path: '/ngnIndex/:id', name: 'ngnIndex', component: () => import('../views/h5pay/nrlyPay/index/index.vue')},
    {
        path: '/creditCard/:id',
        name: 'creditCard',
        component: () => import('../views/h5pay/nrlyPay/Merchant/creditCard.vue')
    },
    {
        path: '/payStatus/:id',
        name: 'payStatus',
        component: () => import('../views/h5pay/nrlyPay/Merchant/PayStatus.vue')
    },
    {
        path: '/oauth2Callback/:CloudPayToken',
        name: 'Oauth2Callback',
        component: () => import('../views/Oauth2Callback.vue')
    },
    {path: '/qrcodepay/:id', name: 'qrcodepayPage', component: () => import('../views/qrcode/qrcodePay')}, // 二维码
    {path: '/qrcodeSave/:id', name: 'qrcodeSavePage', component: () => import('../views/qrcode/qrcodeSave.vue')},
    {path: '/qrcodeOrderIdpush/:id', name: 'qrcodeOrderIdpushPage', component: () => import('../views/qrcode/TC2023')}, // 二维码支付订单号上传
    {
        path: '/qrcodeOrderStatePage/:id',
        name: 'qrcodeOrderStatePage',
        component: () => import('../views/qrcode/PayStatus')
    }, // 二维码支付成功状态展示页
    {path: '/CardPay/:id', name: 'CardPay', component: () => import('@/views/CardBankCardPay/CardPay.vue')},

    {path: '/upi-pay/:id', name: 'upiPay', component: () => import('../views/upi/Pay.vue')}, // upi收银台
    {path: '/pay-success', name: 'paySuccess', component: () => import('../views/upi/PaySuccess.vue')}, // 支付成功
    {path: '/pay-fail', name: 'payFail', component: () => import('../views/upi/PayFail.vue')}, // 支付失败
    //oauth回调地址
    // {
    //     path: '/cashier', name: 'Cashier', component: () => import('../views/Cashier.vue'), //收银台（该地址无意义）
    //     children: [
    //         {path: '/cashier/wxpay', name: 'CashierWxpay', component: () => import('../views/payway/Wxpay.vue')},
    //         {path: '/cashier/alipay', name: 'CashierAlipay', component: () => import('../views/payway/Alipay.vue')},
    //         {path: '/cashier/ysfpay', name: 'CashierYsfpay', component: () => import('../views/payway/Ysfpay.vue')}
    //     ]
    // }
]

const router = new VueRouter({
    mode: 'hash', //history 需要nginx适配    hash：是#的格式。
    base: "",
    routes
})

export default router
