import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import wayCode from './utils/wayCode';
import config from './config'
import VueSimpleAlert from "vue-simple-alert";

// 按需引入element-ui组件
import {Message} from 'element-ui';

import {
    Pagination,
    Dialog,
    Autocomplete,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    TimePicker,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Tree,
    Alert,
    Slider,
    Icon,
    Row,
    Col,
    Upload,
    Progress,
    Spinner,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Carousel,
    CarouselItem,
    Collapse,
    CollapseItem,
    Cascader,
    ColorPicker,
    Transfer,
    Container,
    Header,
    Aside,
    Main,
    Footer,
    Timeline,
    TimelineItem,
    Link,
    Divider,
    Image,
    Calendar,
    Backtop,
    PageHeader,
    CascaderPanel,
} from 'element-ui';

Vue.use(Pagination);
Vue.use(Dialog);
Vue.use(Autocomplete);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Menu);
Vue.use(Submenu);
Vue.use(MenuItem);
Vue.use(MenuItemGroup);
Vue.use(Input);
Vue.use(InputNumber);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Button);
Vue.use(ButtonGroup);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(TimeSelect);
Vue.use(TimePicker);
Vue.use(Popover);
Vue.use(Tooltip);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Tag);
Vue.use(Tree);
Vue.use(Alert);
Vue.use(Slider);
Vue.use(Icon);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Spinner);
Vue.use(Badge);
Vue.use(Card);
Vue.use(Rate);
Vue.use(Steps);
Vue.use(Step);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Cascader);
Vue.use(ColorPicker);
Vue.use(Transfer);
Vue.use(Container);
Vue.use(Header);
Vue.use(Aside);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Link);
Vue.use(Divider);
Vue.use(Image);
Vue.use(Calendar);
Vue.use(Backtop);
Vue.use(PageHeader);
Vue.use(CascaderPanel);
// Vue.prototype.$notify = Notification;

// Vue.use(Message)  // 这种方式不建议使用
import 'amfe-flexible'
import {Loading} from 'element-ui';

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

Vue.use(Loading.directive);
Vue.use(ViewUI);

Vue.config.productionTip = false
Vue.use(VueSimpleAlert);
Vue.prototype.$message = Message
Vue.component(Message.name, Message)


/**
 * 路由守卫
 *
 * @author terrfly
 * @date 2021/5/8 07:18
 */
router.beforeEach((to, from, next) => {

    console.log("进入路由守卫", from, to)
    console.log("进入路由守卫", to.name)

    // from: 当前导航正要离开的路由
    // to: 即将要进入的目标路由对象

    // 如果在免登录页面则直接放行
    if (config.errorPageRouteName.includes(to.name) || config.passGuardRouteList.includes(to.name)
    ) {
        // 在免登录名单，直接进入
        next()
        return false
    }

    next({name: config.errorPageRouteName, params: {errInfo: "请通过正确的支付渠道进入！"}})
    return false;
    // //获取不到参数
    // let token = to.params[config.urlTokenName];
    // let token = 'test';  // 不提交
    // if(token){  //放置token信息
    //     config.cacheToken = token;
    // }
    //
    // if(!config.cacheToken) {
    //     next({ name: config.errorPageRouteName, params: { errInfo: "请通过二维码进入支付页面！" } })
    //     return false;
    // }
    //
    // //获取不到支付类型, 需要跳转到错误页面
    // if( ! wayCode.getPayWay() ) {
    //     next({ name: config.errorPageRouteName, params: { errInfo: "不支持的支付方式！ 请在微信/支付宝/银联应用内扫码进入！" } })
    //     return false;
    // }

    // next()
})


new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
