/*
*  全局定义信息
*
*  @author terrfly
*  @site https://www.cloudpay.vip
*  @date 2021/5/8 07:18
*/

const errorPageRouteName = 'Error' //错误页面名称定义
const ACardPay = 'ACardPay' //个卡支付
const CardPay = 'CardPay' // 卡商支付
const PublicPay = 'PublicPay' //公户支付
const ACardPayComplete = 'ACardPayComplete' //错误页面名称定义
const NrlyPay = 'NrlyPay' // 尼日利亚 支付页面
const H5plugin = 'H5plugin' // 尼日利亚 支付页面
const saveRollInfo = "saveRollInfo"
const saveOrderNumber = "saveOrderNumber"
const qrCodeInfo = "qrCodeInfo"
const ngnIndex = "ngnIndex"
const creditCard = "creditCard"
const payStatus = "payStatus"
const abc = "abc"
const qrcodepayPage = "qrcodepayPage"
const qrcodeOrderIdpushPage = "qrcodeOrderIdpushPage"
const qrcodeOrderStatePage = "qrcodeOrderStatePage"
const qrcodeSavePage = "qrcodeSavePage" //  支付二维码页面

const successPage = "paySuccess" // 支付成功
const failPage = "payFail" // 支付失败
const upiPay = "upiPay" // upi支付

const passGuardRouteList = [qrcodeSavePage,
    qrcodeOrderStatePage,
    qrcodeOrderIdpushPage,
    qrcodepayPage,
    abc,
    payStatus,
    creditCard,
    ngnIndex,
    errorPageRouteName,
    ACardPay,
    PublicPay,
    ACardPayComplete,
    NrlyPay,
    H5plugin,
    saveRollInfo,
    saveOrderNumber,
    qrCodeInfo,
    CardPay,
    successPage,
    failPage,
    upiPay
]  // 不进入路由守卫的name

/** 定义支付方式 **/
const payWay = {
    WXPAY: {wayCode: "WX_JSAPI", routeName: "CashierWxpay"},
    ALIPAY: {wayCode: "ALI_JSAPI", routeName: "CashierAlipay"}
}

export default {
    errorPageRouteName: errorPageRouteName,
    PublicPay: PublicPay,
    successPage: successPage,
    failPage: failPage,
    passGuardRouteList: passGuardRouteList,
    urlTokenName: "CloudPayToken", //URL传递的token名称
    payWay: payWay,
    cacheToken: ""
}

